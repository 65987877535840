.joditEdit {
    .jodit-container {
        .jodit-workplace {
            background: #FFFFFF;

            .jodit-placeholder {
                color: #808080;
            }
        }
    }
}
