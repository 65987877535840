.onboardingNavbar {
    background: #203864;
    position: relative;
    justify-content: space-between;
}
.percentage {
    color: #FFFFFF;
    height: 1.3vw;
    font-family: "Roboto-Regular";
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6vw;
    letter-spacing: 0em;
    padding-top: 1.5vw;
    width: 8vw;
}

.storeName {
    font-family: "Roboto-Regular";
    font-size: 1vw;
    font-weight: 500;
    line-height: 2.3vw;
    letter-spacing: 0em;
    margin-top: 1px;
}

.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    padding-left: 10vw
}

.logout {
    background-color: transparent;
    color: white;
    border-color: transparent;
    margin-top: -0.0vw;
    margin-right: 2vw;
    height: 1.5vw;


}

.navBar-menu {
    float: left;
    margin-left: 65vw;
}

.logo-center {
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

.deplist {
    max-height: 100px;
    overflow-y: auto;
    padding-left: 0px;
}

.depli {
    float: unset;
    list-style-type: none;
    padding-left: 20px;
    width: 100%;
    overflow-x: hidden;
    padding-right: 20px;

}

.dropdown-menu {

    position: absolute;
    right: 15px !important;
    margin: 0;
    left: 63rem;
    display: block;
    background: #ECECEC;
}

.hide-dropdown {
    display: none;
}

.stickNav {

    position: sticky;
    z-index: 1000;
    top: 0px;
}