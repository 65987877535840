.selectedPlanDetails {
    color: #4472C4;
    font-size: 18px;
    font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 600;
}

.planDescription {
    color: #fff;
    font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    background: #004E7A;
    margin-right: 15px;
}

.themeColor {
    background: #203864;
    width: 90px;
    height: 25px;
    margin-left: 10px;
}

@media screen and (max-width: 1550px) {
    .selectedPlanDetails {
        font-size: 16px;
    }

    .planDescription {
        padding: 10px 25px;
    }
}

@media screen and (max-width: 1100px) {
    .planDescription {
        padding: 10px 15px;
    }

    .themeColor {
        width: 80px;
        height: 20px;
    }
}
