.priceLabel {
    text-decoration: line-through;
    opacity: 0.5;
    margin-left: -20px;
}

.thumbNailContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 50px;
    width: 100%;
}

.cardShape {
    width: 176px;
    height: 270px;
    left: 478px;
    top: 343px;
    background: #ECECEC;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

#thumbId.MuiButtonBase-root {
    width: 100% !important;
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: block;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

#cardName.MuiTypography-body1 {
    font-size: 19px;
    font-family: "Source Sans Pro";
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.00938em;
    color: #233949;
}

#cardModel.MuiTypography-body1 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    text-align: center;
    color: #808080;
}

.inv_price {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #262626;
}
.fontStylesModel{
    font-size: 13px;
    font-weight: 400;
}