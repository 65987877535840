.img-center-store{
    display: flex;
    justify-content: center;
    align-items: center;
}

.collectionImage-store {
    width: 50vh;
    height: 36.44vh;
    top: 50.25vh;
    opacity: 0.8;
    margin-top: 6vw;
}