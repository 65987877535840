.mainContainer1{
    margin-left: 3%;
}
.collection{
    padding-top: 3%;
    margin-left: 1%;
    padding-bottom: 2%;
    font-family: "Roboto-Medium";
    font-weight: 570;
    font-size: 21px;
    line-height: 23px;
    color: #11161B;
}
.collText{
    margin-left: 1%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

color: #656565;
}
.createCollection{
    background-color: #ffffff;
    border: 1px solid #233949;
    box-sizing: border-box;
    border-radius: 2px;
    width: 167px;
    height: 51px;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    float: right;
    // margin-left: 65vw;
   
}
.collectionImage{
    width: 50vh;
    height: 36.44vh;
    top: 50.25vh;
    opacity: 0.8;
    margin-top: -2vw;
}
.col-imagee{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8vw;
}