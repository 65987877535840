.outerDiv {
    display: flex;
}
.loginBusinessDeal {
    height: 100vh;
    width: 50%;
    vertical-align: middle;
    background: hsl(206, 44%, 94%);
    text-align: center;
}
.loginForm {
    background: #FFFFFF;
    text-align: justify;
    align-items: center;
    margin: auto;
}
.shopezImage {
    position: relative;
    top: 22%;
    width: 25vw;
}

.loginPlaceholder {
    position: relative;
    top: 26%;
    font-weight: 400;
    color: #233949;
    opacity: 0.5;
    font-size: '18px';
    font-family: "Segoe UI", Arial, sans-serif;
}

.loginForm .shopezzLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 18px;
}
.back_login{
    padding-top: 5vw;
    padding-right: 5vw;
    cursor: pointer;
}
.back_arrow_login{
    color: #233949;
}

.login_otp_card_container {
    .otp-input {
        input {
            width: 50px !important;
            background: #fff !important;
            border: none !important;
            border-bottom: 1px solid #ECECEC !important;
            border-radius: 0;
            margin-left: 10px;

            &:focus-visible {
                outline: 0;
            }
        }
    }
}

