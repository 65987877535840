
.reviewBox{
    background-color:#ECECEC;
}
.leadBox-details{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    color: #606060;
}