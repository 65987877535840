.menu {
    background: #233949;
    height: 260vh;
    width: 17vw;
    padding-top: 20px;

    .nav-link {
        color: #919CA4 !important;
        text-decoration: none;
        display: block;
        padding: 1.5vw 1vw;
        margin-left: 1.5vw;
        border: 2px solid;

        .active-border {
            display: none;
        }

        img {
            width: 1.7vw;
            margin-right: 2vw;
        }
    }

    .submenu-nav-link {
        color: #919CA4 !important;
        text-decoration: none;
        display: block;
        padding: 1.4vw 1vw;
        padding-left: 6vw;

        img {
            width: 1.7vw;
            margin-right: 2vw;
        }
    }

    .submenu-link-active {
        color: #233949 !important;
        padding-left: 6vw;
        font-family: "Roboto-Regular";
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
    }

    .link-active {
        color: #fff !important;
        padding: 1.5vw 1.3vw;
        border-left: 8px solid #ffffff;
        width: 110px;
        border-radius: 0px;
        font-family: "Roboto-Regular";
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            transition: all 0.25s ease;
            animation: fadeInRight .25s ease forwards;
            opacity: 0;
            color: #fff;

            &:hover {
                opacity: 0.8;
                transition: all 0.25s ease;
                cursor: pointer;
            }

            &:active {
                opacity: 0.8;
                transition: all 0.25s ease;
                cursor: pointer;
            }
        }
    }
}

.menuOnboarding {
    background: #ECECEC;
    width: 220px;

    .nav-link {
        color: #233949 !important;
        opacity: 0.6 !important;
        text-decoration: none;
        display: block;
        padding: 15px 15px;

        .active-border {
            display: none;
        }

        img {
            width: 1.7vw;
            margin-right: 2vw;
        }
    }

    .submenu-nav-link {
        color: #233949 !important;
        opacity: 0.6 !important;
        text-decoration: none;
        padding: 15px 25px 15px 65px;
        line-height: 50px;

        img {
            width: 1.7vw;
            margin-right: 2vw;
        }
    }

    .submenu-link-active {
        color: #808080 !important;
        padding: 15px 25px 15px 65px;
        font-family: "Segoe UI", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
    }

    .link-activeOnboard {
        color: #000000 !important;
        border-left: 5px solid #203864;
        font-family: "Segoe UI", Arial, sans-serif;
        font-style: normal;
        font-weight: 600;
        display: block;
        padding: 15px 10px;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            transition: all 0.25s ease;
            animation: fadeInRight .25s ease forwards;
            opacity: 0;
            color: #fff;

            &:hover {
                opacity: 0.8;
                transition: all 0.25s ease;
                cursor: pointer;
            }

            &:active {
                opacity: 0.8;
                transition: all 0.25s ease;
                cursor: pointer;
            }
        }
    }

    .onProcessNav {
        .nav-link {
            padding: 15px 30px;
        }

        .link-activeOnboard {
            padding: 15px 25px;
        }
    }
}

.active {
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
    width: 8vw;
    // height: 260vh;
}

.linkMenuActiveOnboard {
    color: #233949 !important;
    padding-left: 2vw !important;
    padding: 15px 15px;
    border-left: 8px solid #233949;
    width: 110px;
    border-radius: 0px;
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: grid;
    text-align: center;
}

.linkMenuInActiveOnboard {
    color: #919CA4 !important;
    text-decoration: none;
    display: grid;
    padding: 1.5vw 1vw;
    text-align: center;
}

.linkMenuActive {
    color: #fff !important;
    padding-left: 2vw !important;
    padding: 15px 15px;
    border-left: 8px solid #ffffff;
    width: 110px;
    border-radius: 0px;
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: grid;
}

.linkMenuInActive {
    color: #919CA4 !important;
    text-decoration: none;
    display: grid;
    padding: 1.5vw 1vw;
    margin-left: 1.5vw;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 50%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 50%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

.sidenavImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 1rem;
}

.sidenavName {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    color: #919CA4;
}

.user-sidenav {
    position: relative;
}

::-webkit-scrollbar {
    display: none;
}

.sideNav {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #ECECEC;
    overflow-x: hidden;
    margin-top: 64px;
    padding-bottom: 20px;
}

@media screen and (max-width: 1550px) {
    .menuOnboarding {
        width: 200px;
    }
}