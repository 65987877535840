.paddingRight08 {
    padding-Right: 0.8vw;
    opacity: 0.6;
    transition: 0.5s
}
.paddingRight08:hover {
    opacity: 1
}
.paddingRight0 {
    padding-Right: 0vw;
    opacity: 0.6;
    transition: 0.5s
}

.paddingRight0:hover {
    opacity: 1
}
.iconColorArrow{
   color: #000000;
}
.noMatch {
    margin-left: 10px;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.listSectionAddproduct {
    border-collapse: separate;
    border-spacing: 0vw 1vw;
}
.prolisting-color {
    text-align: left;
    height: 2vw;
    width: 5px;
    padding: 0!important;
    margin: 0!important;
}

@media screen and (max-width: 1550px) {
    .inventoryIcon {
        .iconColorArrow{
            font-size: 26px !important;
        }
    }

    .inventoryImgUploadIcon {
        height: 35px !important;
        margin-top: -5px;
    }
}

@media screen and (max-width: 1100px) {
    .inventoryIcon {
        .iconColorArrow{
            font-size: 22px !important;
        }
    }

    .inventoryImgUploadIcon {
        height: 30px !important;
        margin-top: -5px;
    }
}




