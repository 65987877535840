.custom-time-picker {
    padding-top: 25px !important;

}

.fuel-title {
    margin-top: 15px !important;
    margin-left: 2px !important;
}

.fuel-list {
    margin-left: 5px !important;
}

@media (max-width:1199px) {
    .custom-time-picker {
        padding-top: 0px !important;
    }

    .fuel-title {
        margin-top: 0px !important;
        margin-left: 0px !important;
    }

    .fuel-list {
        margin-left: 0px !important;
    }
}