.checkbox-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #858585;
}
.discheckbox{
    opacity: 0;
}
.checkbox{
    width: 20vw
}
.btn-checkbox{
    display: inline-block;
    font-weight: 400;
    color: #000;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid #C0C0C0;
    padding: 0.375rem 0.75rem;
    font-size: 20px;
    line-height: 1.5;
    height:45px;
    border-radius: 4px;
    border-radius: 0px 4px 4px 0px !important;
    border-left: 0px !important;
}
.reviewBox_promote {
    background-color: #EEEEEE;
    width: 100%; 
    padding-bottom: 10px;
}
.edit-small {
    height: 45px;
    width: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
.hide{
    display: none ;
}

@media screen and (max-width: 1550px) {
    .edit-small {
        height: 35px;
        width: 35px;
    }

    .btn-checkbox { 
        height: 40px;
    }
}
@media screen and (max-width: 1100px) {
    .edit-small {
        height: 30px;
        width: 30px;
    }

    .btn-checkbox { 
        height: 35px;
    }
}
