
.forgotPasswordDeal {
    height: 61.2rem;
    background: #233949;
    text-align: center;
    padding-right: 10vh;

    .logo_text_wel {
        font-family: "Segoe UI", Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        color: #808080;
        margin-top: 12rem;
        margin-left: 9rem;
    }
}
.forgot_card_container {
    height: 50rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    margin-left: -30px;
    margin-top: 85px;
    margin-bottom: 85px;
    box-shadow: 0px 5px 24px rgba(201, 201, 201, 0.5);
    position: absolute;
    right: 50px;
}
.logo_forgot {
    position: relative;
    top: 36%;
}