.userDetailsBox{
    background-color: #EEEEEE;
}
.user-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #BCBCBC;

}
.user-name{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #595959;
}
.user-email{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    color: #858585;
}
.user-icons{
    width: 15px;
    height: 12px;
}
.disableUser-btn {
    cursor: pointer;
    background-color: #233949;
    border: 1px solid #233949;
    box-sizing: border-box;
    border-radius: 2px;
    color: #ffffff;
    width: 175px;
    height: 48px;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}
.width75{
    width: 70vw;
}
.width76{
    width: 76vw;
}
