.linkdaysBlockActive {
    background-color: #233949;
    color: #ffffff;
    font-size: 14px;
    padding: 12px;
    border-radius: 4px;
    margin-right: 12px;
    width: 45px;
    height: 45px;
    text-align: center;
    cursor: pointer;
}

.linkdaysBlockInactive {
    color: #808080;
    font-size: 14px;
    padding: 12px;
    border-radius: 4px;
    margin-right: 15px;
    width: 45px;
    height: 45px;
    text-align: center;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #cecece;
}

.subheadings {
    font-size: 14px;
    color: #808080;
    font-weight: 400;
    margin-bottom: 5px;
}

@media screen and (max-width: 1550px) {
    .linkdaysBlockInactive {
        width: 40px;
        height: 40px;
        font-size: 12px;
        padding: 11px;
    }
    .linkdaysBlockActive {
        width: 40px;
        height: 40px;
        font-size: 12px;
        padding: 11px;
    }

    .subheadings {
        font-size: 12px;
    }
}

@media screen and (max-width: 1100px) {
    .linkdaysBlockInactive {
        width: 35px;
        height: 35px;
        padding: 8px;
    }
    .linkdaysBlockActive {
        width: 35px;
        height: 35px;
        padding: 8px;
    }
}