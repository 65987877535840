.enquiry-text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: calc(1em + 1vw);
    line-height: 40px;
    color: #233949;
}
.headIcon{
    width: 30px;
    height: 30px;
}
/*
@media screen and (max-width: 1100px) {
    .headIcon{
        width: 25px;
        height: 25px;
    }
    .enquiry-text{
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}*/