.daysBlock {
    background-color: #233949;
    color: #ffffff;
    font-size: 12px;
    padding: 4px;
    border-radius: 5px;
    margin-right: 4px;
    width: 25px;
    height: 25px;
    text-align: center;
}

.crossModel {
    height: 30px;
    width: 30px;
    background-color: #233949;
    color: #ffffff;
    text-align: center;
    border-radius: 15px;
    padding: 4px;
    margin-left: 22vw;
}

.typesCamp {
    font-size: 22px;
    font-weight: 500;
    border-bottom: 1px solid;
    padding: 10px;
    color: #919CA4;
}

.modalListing {
    padding: 10px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1550px) {
    .daysBlock {
        font-size: 10px;
        width: 22px;
        height: 22px;
    }

    .crossModel {
        padding: 6px 6px;
        margin-left: 21vw;
    }

    .modalListing {
        padding: 0px 10px;
        margin-top: 1rem !important;
    }
}

@media screen and (max-width: 1550px) {
    .crossModel {
        margin-left: 29vw;
    }
}