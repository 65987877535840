.discountImage{
    opacity: 0.8;
}
.dealImageCenter{
    display: flex;
    justify-content:center ;
    align-items: center;
    margin-top: 12vw;
}
.discountDetails{
  .textStyle{
    display: flex;
    align-items: center;
  }
}

.discountCategory{
    display: flex;
    justify-content: center;
    align-items: center;
}

.discountConditionStyle{

  .MuiButtonBase-root-MuiRadio-root {
    padding: 9px;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.6);
}

  .MuiFormControlLabel-root {
          width: max-content;
      .label{
         width: max-content;
      }
  }

}

.defaultInput {
    background: #FFFFFF !important;
    padding: 10px 15px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #C0C0C0;
}

.discountProductList {
    .listItems {
        position: absolute;
        left: 0;
        z-index: 99;
        right: 0;
        background: #FFFFFF;
        overflow-y: scroll;
        max-height: 500px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
    }

    .productlist{
        border-bottom: 1px solid #C0C0C0;
        padding:10px 10px 10px 10px;
        display: flex;
        align-items: center;
    }
}

.discountCollectionList {
    .listItems {
        position: absolute;
        left: 0;
        z-index: 99;
        right: 0;
        background: #FFFFFF;
        overflow-y: scroll;
        max-height: 500px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
    }

    .collectionlist{
        border-bottom: 1px solid #C0C0C0;
        padding:10px 10px 10px 10px;
        display: flex;
        align-items: center;
    }
}




