.paddingRight80{
    padding-Right: 0.8vw;
    opacity: 0.6;
    transition: 0.5s
}
.paddingRight80:hover{
    opacity: 1
}
.paddingRight00{
    padding-Right: 0vw;
    opacity: 0.6;
    transition: 0.5s
}
.paddingRight00:hover{
    opacity: 1
}
.searchbar{
   padding-left: 2vw;
    width: 40vw;
    height: 2.5vw;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.listSectionAddItem{
    // margin-top: 1.5vw;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0vw 1vw;
}