.variantsDescription{
    font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0em;
    color: #606060;
}
.closeIcon{
    float: right;
    padding: 5px;
}
.info {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    text-align: center;
    justify-content: center;
    border-top: 1px solid #C0C0C0;
    border-bottom: 1px solid #C0C0C0;
    border-right:1px solid #C0C0C0;
}
.removeVariant {
    background-color: #FFFFFF;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C0C0C0;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.removeAddOns {
    background-color: #FFFFFF;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C0C0C0;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.hideColor{
    display: none;
}

@media screen and (max-width: 1550px) {
    .info {
        height: 40px;
    }
    .removeVariant {
        height: 40px;
    }
    .removeAddOns {
        height: 40px;
    }
}

@media screen and (max-width: 1100px) {
    .info {
        height: 35px;
    }
    .removeVariant {
        height: 35px;
    }
    .removeAddOns {
        height: 35px;
    }
}
