.roleBox{
    width: 100%;
    height: 350px;
    border-radius: 30px;
    background-color: #ECECEC;
    cursor: pointer;
}
.notifications-icon-md {
    background: #233949;
    border-radius: 4px;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    float: right;
    cursor: pointer;
    margin-left: 55vw;
}
.role-text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    color: #9B9B9B;
    font-size: 14px;
}
.roleMan1{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
}
.image-stack-1{
    position: relative;
    right: 20px;
}
.image-stack-2{
    position: relative;
    right: 40px;
}
.image-stack-3{
    position: relative;
    right: 60px;
}
.roleman-text1{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
}
.roleman-text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    margin-left: -2rem;
}


@media screen and (max-width: 1550px) {
    .role-text {
        font-size: 12px;
    }

    .roleman-text { 
        font-size: 14px;
    }

    .roleman-text1 {
        font-size: 14px;
    } 

    .roleMan1 {
        width: 40px;
        height: 40px;
    }

    .roleBox {
        height: 310px;
    }

    .enquiry-text {
        font-size: 32px;

        img {
            width: 35px !important;
        }
    }
}

@media screen and (max-width: 1100px) {
    .enquiry-text {
        font-size: 28px;
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;

        img {
            width: 25px !important;
        }
    }
    .role-text {
        margin-top: 2.5rem !important;
    }
    .image-stack-1 {
        right: 15px;
    }
    .image-stack-2 {
        right: 30px;
    }
    .image-stack-3 {
        right: 45px;
    }
}