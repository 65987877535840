.createBusinessDeal {
    vertical-align: middle;
    background: #233949;;
    text-align: center;
    padding-right: 10vh;
}
.logo{
    position: relative;
    top: 30%;
}
.logo_text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 38px;
    text-align: center;
    color: #858585;
}
.create_card_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-left: -30px;
    margin-top: 21%;
    margin-bottom: 21%;
    box-shadow: 0px 5px 24px rgba(201, 201, 201, 0.5);
    position: absolute;
    right: 50px;
}
.create_otp_card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;
    margin-left: -30px;
    margin-top: 90px;
    margin-bottom: 90px;
    box-shadow: 0px 5px 24px rgba(201, 201, 201, 0.5);
    position: absolute;
    right: 50px;
    height: 50rem;

    .otp-input {
        input {
            width: 50px !important;
            background: #fff !important;
            border: none !important;
            border-bottom: 1px solid #ECECEC !important;
            border-radius: 0;
            margin-left: 10px;

            &:focus-visible {
                outline: 0;
            }
        }
    }
}


#create-card{
    position: relative;
}
.back_arrow{
    color: #fff;
}
.back{
    padding-top: 5vw;
    padding-right: 5vw;
    cursor: pointer;
}
.flagSize{
    width: 30px;
    height: 18px;
}
.location_icon{
    position: absolute;
    margin-left: 22vw;
}

.inputLocationIcon {
    background: #FFF;
    height: 45px;
    border: 1px solid #C0C0C0;
    border-radius: 0px 4px 4px 0px !important;
    border-left: 0px !important;
    padding: 12px 0px;
}

.geosuggest-left-top-bottom {
    .geosuggest {
        .geosuggest__input-wrapper {
            .geosuggest__input {
                border-radius: 4px 0px 0px 4px !important;
                border-right: 0px !important;
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .create_card_container {
        margin-top: 15%;
        margin-bottom: 15%;
    }

    .inputLocationIcon {
        height: 40px;
        padding: 8px 0px;
    }
}
@media screen and (max-width: 1100px) {
    .inputLocationIcon {
        height: 35px;
        padding: 6px 0px;

        img {
            margin-left: -15px;
        }
    }
}

