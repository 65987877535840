.isoTerritoriesList {

    .isoTerritoriesItem {
        border-radius: 0 !important;
        margin-bottom: 15px;
        box-shadow: none;
        background-color: #ECECEC;

        .isoTerritoriesTitle {
            font-family: "Segoe UI", Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            .isoTerritoriesContent {
                img {
                    margin-right: 10px;
                }
            }
        }
    
        .isoTerritoriesDetails {
            p {
                width: 100%;

                .isoTerritoriesItem {
                    background-color: #FFFFFF;
                    font-size: 14px;

                    .MuiAccordionSummary-content{
                        margin: 15px 0;
                    }

                    .isoTerritoriesContent {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .isoTerritoriesDetails {
                        margin-left: 15px;
                        padding: 15px 15px;
                        margin-right: 15px;
                        display: block;
                        font-size: 14px;

                        .row {
                            padding-bottom: 15px !important;
                            padding-top: 15px !important;
                            border-bottom: 1px solid #fff;
                            background: #ECECEC;
                        }
                    }
                }
                
            }
        }
    }
}

.operational {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
}

.MuiButtonBase-root {
    .MuiAccordionSummary-content {
        .MuiTypography-root {
            font-size: 14px;

            img {
                width: 45px !important;
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .operational {
        font-size: 12px !important;
    }

    .isoTerritoriesList {
        .isoTerritoriesItem {
            .isoTerritoriesTitle {
                font-size: 12px !important;
            }
            .isoTerritoriesDetails {
                font-size: 12px !important;
            }
        }
    }

    .MuiButtonBase-root {
        .MuiAccordionSummary-content {
            .MuiTypography-root {
                font-size: 12px !important;

                img {
                    width: 35px !important;
                }
            }
        }
    }
}
/*
@media screen and (max-width: 1100px) {
    .operational {
        font-size: 12px;
    }

    .isoTerritoriesList {
        .isoTerritoriesItem {
            .isoTerritoriesTitle {
                font-size: 12px;
            }
            .isoTerritoriesDetails {
                font-size: 12px;
            }
        }
    }

    .MuiButtonBase-root {
        .MuiAccordionSummary-content {
            .MuiTypography-root {
                font-size: 12px;

                img {
                    width: 40px !important;
                }
            }
        }
    }
}*/