.FinancialBox {
    width: 100%;
    background-color: #ECECEC;
    min-height: 23rem;
}

.bank_verified {
    margin-left: 5px;
    background-color: #d4edda;
    color: #155724;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 600;
}

.bank_unverified {
    margin-left: 5px;
    background-color: #f8d7da;
    color: #721c24;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 600;
}