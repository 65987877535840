.previous-order-box{
    background-color: #EEEEEE;
    height: 20.8rem;
    width: 74.5vw;
}
.order-his-text{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #BCBCBC;
}
.OrderSection-pre {
    cursor: pointer;
    margin-top: 0px;
    width: 74.5vw;
    border-collapse: separate;
    border-spacing: 0vw 1vw;
}