.welcome_img {
    height: 65rem;
}

.create_welcome_card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;
    margin-left: -30px;
    margin-top: 85px;
    margin-bottom: 82px;
    box-shadow: 0px 5px 24px rgba(201, 201, 201, 0.5);
    position: absolute;
    right: 50px;
    height: 55rem;
}

.welcomelogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #646464;
}
.create_welcome_card_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;
    margin-left: -30px;
    margin-top: 85px;
    margin-bottom: 82px;
    box-shadow: 0px 5px 24px rgba(201, 201, 201, 0.5);
    position: absolute;
    right: 122px;
    height: 55rem;
}