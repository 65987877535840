.createCollectionTab{
    padding-top: 2vw;
    // margin-left: 0.8vw;
    font-family: "Roboto-Medium";
    font-weight: 570;
    font-size: 21px;
    line-height: 23px;
    color: #11161B;
}
.width70{
    width: 100%;
    margin-top: 1vw;

//     .MuiTabs-root .MuiTabs-fixed .MuiTabs-flexContainer .MuiTab-root {
//     background:none; 
//     border-width: none;
//     border-style: none; 
//     border-color: none; 
//     min-height: 48px !important;
// }

// .MuiTabs-root .MuiTabs-fixed .MuiTabs-indicator {
//     background-color: #233949; 
//     height: 3px;
// }

// .MuiTabs-root .MuiTabs-fixed .MuiTabs-flexContainer {
//     padding-left: 0px; 
//     border-bottom: 1px solid #C0C0C0; 
// }

}
.width71{
    width: 100%;
    margin-top: 1vw;
}
.saveBButton{
    float: right;
    // margin-right: 2vw;
    margin-top: 2vh;
    width: 139px;
    height: 51px;
    background: #233949;
    border-radius: 2px;
    border: none;
    color: #FFFFFF;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}
.button{
    width: 100px;
    height: 40px;
    background: #233949;
    border-radius: 2px;
    border: none;
    color: #FFFFFF;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}
.cancelBButton{
    float: right;
    margin-right: 1vw;
    margin-top: 2vh;
    width: 132px;
    height: 51px;
    background: #CECECE;;
    border-radius: 2px;
    border: none;
    color: #566671;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}
.horizontalList{
    background-color: #ECECEC;
    border-radius: 20px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
}
.deleteView{
    margin: 10px;
    width: 20px;
    text-align: center;
    border-radius: 16px;
    color: #FFFFFF;
    background-color: #233949;
}

.addCollectionProduct{

    .searchbar{
       
    }

    .listItems{
        // box-shadow: 1px 2px 9px #C0C0C0;
        position: absolute;
        left: 0;
        z-index: 99;
        right: 0;
        // top: 0;
        background: #FFFFFF;
        overflow-y: scroll;
        max-height: 500px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
    }

    .productlist{
        border-bottom: 1px solid #C0C0C0;
        padding:10px 10px 10px 10px;
        display: flex;
        align-items: center;

     
    }
}


.headers{
    font-family: "Roboto-Medium";
    font-weight: 600;
    font-size: 14px;
    // line-height: 24px;
    color: #11161B;
}