.outerView {
    background: #E8F0F6;
    height: 100%;
}

.company {
    padding-top: 10px;
    padding-bottom: 10px;

}

.userName {
    padding-bottom: 10px;
    padding-top: 0px;
}

.signupButton {
    height: 46px;
    width: 30vw;
    background: #233949;
    border-radius: 2px;
    border: none;
    color: #FFFFFF;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 30px
}

.company .MuiFormControl-root {
    width: 100%;
}

.userName .MuiFormControl-root {
    width: 100%;
}

.password {
    padding-bottom: 10px;

}

.signupForm {
    margin-top: 5vh;
    padding-right: 5vw;
}

.formSection {
    background: #FFFFFF;
    padding: 3vw;
    margin-top: 25vh;
}

.password .MuiFormControl-root {
    width: 100%;
}

.signupBusinessDeal {
    vertical-align: middle;
    background: #E8F0F6;
    position: relative;
    padding-bottom: 40vh;
    padding-top: 10vh;
    padding-left: 5vw;

}

.signupLogoView {
    margin-left: 25vw;

}

.headingMain {
    color: #233949;
    font-family: "Roboto-Regular";
    font-size: '22px';
    font-weight: bold;
}

.headingSmall {
    color: #233949;
    opacity: 0.5;
    font-family: "Roboto-Regular";
    font-size: '18px';
}

.shopezImagesignup {
    position: relative;
    top: 25%;
    height: 65vh;
}

.back-signup {
    cursor: pointer;
}