.tableSection{
    margin-top: 7%;
    width:100% !important;
    border-collapse: separate;
    border-spacing: 0px 15px;
}
.editIcon{
    padding-right: 15px;
}
.productIcon{
    padding-left: 25px;
}
react-switch-handle{
    height: 14px !important;
    width: 14px !important;
    background: rgb(255, 255, 255);
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    transform: translateX(29px);
    top: 6px !important;
    outline: 0px;
    border: 0px;
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}