.tellUsLabel {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    font-size: 14px;
}
.frame {
    white-space: nowrap;
    text-align: center;
    background: #ECECEC;
    border-radius: 2px;
}

.d-contents {
    display: contents;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.corner {
    position: absolute;
    width: 22px;
    height: 24px;
    left: 648px;
    top: 448px;
    background: #233949;
}

.rc-tooltip-inner {
    padding: 8px 10px;
    color: #808080 !important;
    text-align: left;
    text-decoration: none;
    background-color: #FFFFFF !important;
    border-radius: 6px;
    min-height: 34px;
    font-size: 12px !important;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
    padding: 9px 0 5px 0;
    width: 19% !important;
}

.toolTipContent {
    display: block;
    width: 350px;
    word-wrap: break-word;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 4px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #ffffff !important;
}

.storeDeleteImage {
    width: 26px;
    height: 26px;
    position: absolute;
    color: #FFFFFF;
    border-radius: 50%;
    margin-left: 75px !important;
    margin-top: -12px !important;
    background: #000;
    padding-top: 2px;
}

.x {
    text-align: right;
}

@media screen and (max-width: 1550px) {
    .tellUsLabel {
        font-size: 14px;
    }

    .viewAll {
        font-size: 14px;
    }
}
/*
@media screen and (max-width: 1100px) {
    .tellUsLabel {
        font-size: 12px;
    }

    .viewAll {
        font-size: 12px;
    }
}*/