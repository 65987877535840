.collectionName{
    width: 100%;
    padding: 15px;
    margin-left: 10vw;
    background: #ECECEC;
    height: 60px;
    // display: inline-block;
}
.description{
    width: 100%;
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid#C0C0C0;
    border-radius: 4px;
    outline:none;
}
.textFieldDetailSection{
    margin-top:0px;

    // .MuiFormControl-root .MuiInputBase-root {
    //     border-radius: 4px;
    //     height: 60px;
    //     padding: 15px;
    //     color: #000;
    //     font-family: "Segoe UI", Arial, sans-serif;
    //     font-style: normal;
    //     font-weight: 400;
    //     background-color: transparent;
    //    }
}
.textFieldDetailDateSection{
    margin-top:10px;

//     .MuiFormControl-root .MuiInputBase-root {
//     border-radius: 4px;
//     height: 60px;
//     padding: 15px;
//     color: #000;
//     font-family: "Segoe UI", Arial, sans-serif;
//     font-style: normal;
//     font-weight: 400;
//     background-color: transparent;
//    }
}
.TextFieldDate30{
    width: 100%;
    padding-right: 10px;
    background: #ECECEC;
    height: 60px;
}

