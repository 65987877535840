.noti-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #233949;
}
.notifications-icon {
    background: #233949;
    border-radius: 15px;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    float: right;
    cursor: pointer;
    margin-left: 73vw;
}
.Days{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #11161B;
}
.notification-Section{
    cursor: pointer;
    margin-top: 0px;
    width: 77vw;
    border-collapse: separate;
    border-spacing: 0vw 1vw;
}
.noti-icon{
    width: 25px;
    height: 30px;
}