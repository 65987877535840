.logo_text_wel {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #858585;
    margin-top: 20rem;
    margin-right: 7rem;
}

.createBusinessDeal_setPassword {
    vertical-align: middle;
    background: #233949;
    text-align: center;
    height: 65rem;
}

.logo_setPass {
    position: relative;
    top: 47%;
    margin-right: 1rem;
}

.logo_text_frgot {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #858585;
    margin-top: 20rem;
}

.ErrorOccured {
    margin-left: 10vw;
    font-size: 14px;
    color: "Red";
    padding-top: 10px;
}
.eyeIcon{
    position: absolute;
    margin-left: 25vw;
}